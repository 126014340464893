import React, {useState, createRef, useEffect, useContext} from 'react';
import {history, AppStateContext, lighterColor} from '../index';
import {Icon} from "semantic-ui-react";


type PlayerState = "play" | "stop";


export function AudioPlayerWidget() {
    const [appState] = useContext(AppStateContext);
    const [state, setState] = useState<PlayerState>("stop");
    const [time, setTime] = useState<string>("00:00");
    const [audio] = appState.audios.filter(a => a.lang === appState.currentTranslation?.lang);
    const ref = createRef<HTMLAudioElement>();

    const onClick = () => {
        switch(state) {
            case "play": ref.current?.pause(); setState("stop"); break;
            case "stop": ref.current?.play(); setState("play"); break;
        }
    };

    const className = () => {
        switch(state) {
            case "play": return "playing";
            case "stop": return "init";
        }
    };

    const buttonName = () => {
        switch(state) {
            case "play": return "Pausa";
            case "stop": return "Play";
        }
    };

    const twoPlaces = (n: number) => {
        if(n < 10) return "0" + n.toString();
        return n.toString();
    };

    useEffect(() => {
        const l = () => {
            const time = (ref.current?.currentTime);
            if(time == null) return;
            const timeTrimmed = parseInt(time.toString());
            const seconds = timeTrimmed % 60;
            const minutes = parseInt((timeTrimmed / 60).toString());
            setTime(twoPlaces(minutes) + ":" + twoPlaces(seconds));
        };

        const e = () => { history.push("/questionary"); };

        ref.current?.addEventListener("timeupdate", l);
        ref.current?.addEventListener("ended", e);
        return () => {
            ref.current?.removeEventListener("timeupdate", l);
            ref.current?.removeEventListener("ended", e);
        }
    });
    useEffect(() => {
        document.body.style.backgroundColor = lighterColor;
    }, [0]);

    return (
        <div className="column-space-between">
            <audio src={ `${appState.currentTranslation?.lang}.mp3` } ref={ref} preload="auto"/>
            <h2 className="margin-top-2 margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                { appState.currentTranslation?.audio_title ?? "" }
            </h2>
            <div className="column">
                <h3 style={{fontFamily: 'Libre Baskerville, serif'}}>
                    { appState.currentTranslation?.audio_preface ?? "" }
                </h3>
                <p className="margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                    { (audio?.duration ?? "") !== "" ? (appState.currentTranslation?.audio_duration ?? "") + " " + (audio?.duration ?? "") : ""  }
                </p>
            </div>
            <div className="container">
                <div className="circle-container">
                    <div className={className()}>
                        <h1 style={{fontFamily: 'Libre Baskerville, serif'}}>{time}</h1>
                    </div>
                </div>
            </div>

            <button className="input margin-bottom-2" onClick={ onClick }>
                <Icon name={state === "play" ? "pause" : "play"} className={""} alt={ state === "play" ? "pause" : "play" }/>
            </button>
            
            <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick= {() => history.push("/questionary")}>
                { appState.currentTranslation?.audio_secondary_button ?? "" }
            </button>
        </div>
    );
}