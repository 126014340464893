import React, {useContext, useEffect} from "react";
import {AppStateContext, darkerColor, history} from "../index";

export function Step1() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = darkerColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <h2 className="tutorial_item margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                { appState.currentTranslation?.step1_title ?? "" }
            </h2>
            <div className="img-container">
                <img src="Illustration_time.svg" alt={ "person with hourglass" }/>
            </div>
            <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/step2")}>
                { appState.currentTranslation?.step1_primary_button ?? "" }
            </button>
        </div>
    );
}

export function Step2() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = darkerColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <h2 className="tutorial_item margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                { appState.currentTranslation?.step2_title ?? "" }
            </h2>
            <div className="img-container" style={{fontFamily: 'Libre Baskerville, serif'}}>
                <img src="Illustration_sound.svg" alt={ "person with headphones" }/>
            </div>
            <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/step3")}>
                { appState.currentTranslation?.step2_primary_button ?? "" }
            </button>
        </div>
    );
}

export function Step3() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = darkerColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <h2 className="tutorial_item margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                { appState.currentTranslation?.step3_title ?? "" }
            </h2>
            <div className="img-container" style={{fontFamily: 'Libre Baskerville, serif'}}>
                <img src="Illustration_sit.svg" alt={ "person sitting" }/>
            </div>
            <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ () => history.push("/audio") }>
                { appState.currentTranslation?.step3_primary_button ?? "" }
            </button>
        </div>
    );
}