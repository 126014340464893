import React, {useContext, useEffect} from "react";
import {history, AppStateContext, lighterColor} from "../index";
import {Translation} from "../models/translation";
import { Dropdown } from 'semantic-ui-react'
import {setSelectedLanguage} from "../services/db_service";


export function Intro(props: { translations: Translation[] }) {

    const [appState, setAppState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = lighterColor;
    }, [0]);

    return (
        <div className="column-space-between">
            <div className="column margin-top-2">
                <div className="app_bar margin-top-2">
                    <h1 style={{fontFamily: 'Libre Baskerville, serif'}}>{ appState.currentTranslation?.intro_title ?? "" }</h1>
                </div>
                <div className="description margin-top-2">
                    { (appState.currentTranslation?.intro_text ?? "").split("\n").map(i => <p style={{fontFamily: 'Libre Baskerville, serif'}} key={i}>{i}</p>) }
                </div>
            </div>
            <div className="img-container margin-top-2">
                <img src="Illustration_welcome.svg" alt={"3 Doctors"}/>
            </div>
            <div className="column">
                <button className="input_primary" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/step1")}> { appState.currentTranslation?.intro_primary_button ?? "" }</button>
                <button className="input_flat" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/terms")}>{ appState.currentTranslation?.intro_secondary_button ?? "" }</button>
            </div>
        </div>
    );
}