import {getTranslations} from "../repository/repository";
import {getSelectedLanguage} from "./db_service";
import {Translation} from "../models/translation";

export  const getDefaultTranslation = async (translations: Translation[]) => {
    const selectedLanguage = await getSelectedLanguage();
    const [ translation ] = translations.filter((t) => t.lang === selectedLanguage);

    if(translations.filter(t => t.lang === selectedLanguage).length === 0 && selectedLanguage !== "IT") {
        const [ it ] = translations.filter((t) => t.lang === "IT");
        return it;
    }

    return translation;
};