import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, Link } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { AudioPlayerWidget } from './pages/AudioPlayerWidget';
import { Intro } from './pages/Intro';
import {Credits} from "./Credits";
import {Terms} from "./pages/Terms";
import {Step1, Step2, Step3} from "./pages/Steps";
import {Questionary} from "./pages/Questionary";
import {Thanks} from "./pages/Thanks";
import {Splash} from "./pages/Splash";
import {Translation} from "./models/translation";
import {getAudios, getTranslations} from "./repository/repository";
import * as model from "./models/audio";
import {getDefaultTranslation} from "./services/translation";
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { Contacts } from './pages/Contacts';
import { Login } from './pages/Login';
import { Registration } from './pages/Registration';
import { Gdpr } from './pages/Gdpr';

export const history = createBrowserHistory();

type AppState = {
  currentTranslation: Translation | null,
  audios: model.Audio[],
  jwt: string | null
}

export const AppStateContext = React.createContext<[AppState, Dispatch<SetStateAction<AppState>>]>([{
  currentTranslation: null,
  audios: [],
  jwt: null
}, () => {}]);

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);

export const darkerColor = "#C6E1F9";
export const lighterColor = "#EAFDFD";

serviceWorker.unregister();


function App() {
  const [appState, setAppState] = useState<AppState>({
    currentTranslation: null,
    audios: [],
    jwt: null
  });

  const [translations, setTranslations] = useState<Translation[]>([]);

  useEffect(() => {
      (async () => {
          const translations = await getTranslations();
          setTranslations(translations);
          const audios = await getAudios();
          const currentTranslation = await getDefaultTranslation(translations);
          setAppState({
            ...appState,
              currentTranslation,
              audios
          });
          console.log("Setup lang: " + currentTranslation.lang);
      })();

  }, [0]);

  const url = "audio.mp3";
  return(
      <React.StrictMode>
        <Router history={history}>
          <AppStateContext.Provider value={[appState, setAppState]}>
            <Switch>
            <Route path="/registration">
                <Registration translations={ translations } />
              </Route>
              <Route path="/gdpr" component={Gdpr}></Route>
              <Route path="/login">
                <Login translations={ translations } />
              </Route>
              <Route path="/intro">
                <Intro translations={ translations }/>
              </Route>
              <Route path="/terms" component={Terms} />
              <Route path="/step1" component={Step1} />
              <Route path="/step2" component={Step2} />
              <Route path="/step3" component={Step3} />
              <Route path="/audio" component={AudioPlayerWidget} />
              <Route path="/questionary" component={Questionary} />
              <Route path="/credits" component={Credits} />
              <Route path="/contacts" component={Contacts} />
              <Route path="/thanks" component={Thanks} />
              <Route path="/:lang" component={Splash} />
              <Route component={Splash} />
            </Switch>
          </AppStateContext.Provider>
        </Router>
      </React.StrictMode>
  );
}