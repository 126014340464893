import * as db from '../services/db_service';
import {fetchAudios, fetchTranslations, signin} from "../services/api_service";
import {Translation} from "../models/translation";
import {Audio} from "../models/audio";

export const getTranslations: () => Promise<Translation[]> = async () => {

    try {
        const webTranslations = await fetchTranslations();
        console.log("Online");
        try {
            await db.setTranslations(webTranslations);
        } catch (e) {}

        return webTranslations;
    } catch (e) {
        console.error(e);
        return (await db.getTranslations()) ?? [];
    }

};

export const getAudios: () => Promise<Audio[]> = async () => {

    try {
        const webAudios = await fetchAudios();
        console.log("Online");
        try {
            await db.setAudios(webAudios);
        } catch (e) {}

        return webAudios;
    } catch (e) {
        console.error(e);
        return (await db.getAudios()) ?? [];
    }

};

