import React, {useContext, useEffect} from "react";
import {AppStateContext, history} from "../index";
import {getAudios, getTranslations} from "../repository/repository";
import { useLocation } from "react-router-dom";
import {setSelectedLanguage} from "../services/db_service";

export function Splash() {

    const location = useLocation();
    const [_, lang] = location.pathname.split("/");

    useEffect(() => {

        if(lang.length > 0) {
            setSelectedLanguage(lang.toUpperCase());
        }

        setTimeout(() => history.push("/registration"), 2000)
    },[1]);
    return(
        <div className="splash">
        <div className="column-splash">
            <h2 className="title" style={{fontFamily: 'Libre Baskerville, serif'}}>Profondamente</h2>
        </div>
        <div className="powered" style={{fontFamily: 'Libre Baskerville, serif'}}>Powered by</div>
        <div className="img-container">
            <img src="UQIDO.svg" alt={ "Uqido logo" }/>
        </div>
        </div>
    );
}

