import {fetchVotes} from "./api_service";
import {Vote} from "../models/vote";


type MoodPercentage = {
    happy: number,
    sad: number
}


export const moodPercentage: () => Promise<MoodPercentage> = async () => {
    const votes: Vote[] = await fetchVotes();

    return votes.reduce((acc: MoodPercentage, vote: Vote) => {
        if(vote.mood === "happy") return { ...acc, happy: acc.happy + 1 };
        if(vote.mood === "normal") return { ...acc, happy: acc.happy + 1 };
        if(vote.mood === "sad") return { ...acc, sad: acc.sad + 1 };
        return acc;
    }, {happy: 0, sad: 0} );

};
