import React, {useContext, useEffect, useState} from "react";
import {AppStateContext, darkerColor, history} from "../index";
import {createVote} from "../services/api_service";
import {Input} from "semantic-ui-react";



type pageState = "happy" | "normal" | "sad" | "none"


export function Questionary() {
    const [appState] = useContext(AppStateContext);
    const [pageState, setPageState] = useState<pageState>("none");
    const [text, setText] = useState<string>("");
    useEffect(() => {
        document.body.style.backgroundColor = darkerColor;
    }, [0]);

    const onVote = (s: pageState) => () => setPageState(s);

    const onSubmit = () => {
        createVote(text, "", pageState);
        history.push("/thanks");
    };

    return (
        <div className="column-space-between">
            <div className="column">
                <h2 className="margin-top-2 margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                    Profondamente
                </h2>
                <div className="column">
                    { (appState.currentTranslation?.form_title ?? "La sessione è finita\nCome valuti questa esperienza?").split("\n").map(i => <p style={{fontFamily: 'Libre Baskerville, serif'}} key={i}>{i}</p>) }
                </div>
                <div className="smile-container margin-top-4">
                    <button className="smile-button" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onVote("happy") }>
                        { pageState !== "happy" && <img src={"smile_happy.svg"}/> }
                        { pageState === "happy" && <img src={"smile_happy_selected.svg"}/> }
                    </button>
                    <button className="smile-button" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onVote("normal") }>
                        { pageState !== "normal" && <img src={"smile_neutral.svg"}/> }
                        { pageState === "normal" && <img src={"smile_neutral_selected.svg"}/> }
                    </button>
                    <button className="smile-button" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onVote("sad") }>
                        { pageState !== "sad" && <img src={"smile_sad.svg"}/> }
                        { pageState === "sad" && <img src={"smile_sad_selected.svg"}/> }
                    </button>
                </div>
                { pageState !== "none" && <p style={{fontFamily: 'Libre Baskerville, serif'}} className="margin-top-2">{ appState.currentTranslation?.form_feedback ?? "Dacci un feedback" }</p>   }
                { pageState !== "none" && <textarea rows={ 5 }  className="contact-us" style={{fontFamily: 'Libre Baskerville, serif'}} onChange={(e) => setText(e.target.value) }/>   }
            </div>

            { pageState !== "none" && <button className="input_primary margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ onSubmit }> { appState.currentTranslation?.form_send ?? "Invia" }</button>   }

     </div>
    );
}

// const