import React, {useContext, useEffect} from "react";
import {AppStateContext, darkerColor} from "..";
import {history} from "../index";

export function Thanks() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = darkerColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <h2 className="margin-top-2 margin-bottom-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Profondamente
            </h2>
            <h2 className="tutorial_item margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                { appState.currentTranslation?.thanks_title ?? "Grazie!" }
            </h2>
            <div className="img-container">
                <img src="Illustration_thanks.svg" alt={"high five"}/>
            </div>
            <div className="power" style={{fontFamily: 'Libre Baskerville, serif'}}>Powered by</div>
            <div className="img-container">
                <a href="https://www.uqido.com/it/" style={{fontFamily: 'Libre Baskerville, serif'}}><img src="uqido.png" alt={"uqido logo"} className="uqido-logo"/></a>
            </div>
            <div>
                <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ () => history.push("/contacts") } >{ appState.currentTranslation?.thanks_contacts ?? "Contatti" }</button>
                |
                <button className="input_flat margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={ () => history.push("/credits") } >{ appState.currentTranslation?.thanks_credits ?? "Credits" }</button>
            </div>
        </div>

    );
}

