import * as localForage from "localforage";
import { Translation } from "../models/translation";
import { Audio } from "../models/audio";

localForage.config({
    driver: localForage.INDEXEDDB
});

export const getTranslations: () => Promise<Translation[]> = async () => {
    return localForage.getItem("translations");
};

export const setTranslations = async (translations: Translation[]) => {
    return localForage.setItem("translations", translations);
};

export const getAudios: () => Promise<Audio[]> = async () => {
    return localForage.getItem("audios");
};

export const setAudios = async (audios: Audio[]) => {
    return localForage.setItem("audios", audios);
};

export const getSelectedLanguage: () => Promise<string> = async () => {
    try {
        return (await localForage.getItem("selectedLanguage")) ?? "IT";
    } catch (e) {
        return "IT";
    }
};

export const setSelectedLanguage = async (selectedLanguage: string) => {
    try {
        return localForage.setItem("selectedLanguage", selectedLanguage);
    } catch (e) {
        console.log(e);
    }
};

