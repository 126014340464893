import {Translation} from "../models/translation";
import {Audio} from '../models/audio';
import {Vote} from "../models/vote";


const BASE_URL = "https://profondamente-api.uqido.com";

export const fetchTranslations: () => Promise<Translation[]> = async () => {
    const response: string = await (await fetch(BASE_URL + "/translations")).text();
    const translations: Translation[] = JSON.parse(response);
    return translations;
};


export const fetchVotes: () => Promise<Vote[]> = async () => {
    const response: string = await (await fetch(BASE_URL + "/votes?_limit=-1")).text();
    const votes: Vote[] = JSON.parse(response);
    return votes;
};


export const fetchAudios: () => Promise<Audio[]> = async () => {
    const response: string = await (await fetch(BASE_URL + "/audios")).text();
    const audiosJSON: any[] = JSON.parse(response);

    return audiosJSON.map(a => {
        return {
            ...a,
            url: BASE_URL + a.file.url
        }
    });
};

export const createVote = async (contact_text: string, email: string, mood: string) => {
    const response = await fetch(BASE_URL + '/votes', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            mood,
            email,
            contact_text
        })
    });
    return await response.json();
};

export const signup = async (email: string, password: string) => {
    const response = await fetch(BASE_URL + '/auth/local/register', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: email,
            email,
            password
        })
    });
    const response_json = await response.json();

    if(response.status === 400) throw response_json.message[0].messages.map((m: any) => m.message).join(" ");

    return response_json.jwt;
};



export const signin = async (identifier: string, password: string) => {
    const response = await fetch(BASE_URL + '/auth/local', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            identifier,
            password
        })
    });
    const response_json = await response.json();
    return response_json.jwt;
};
