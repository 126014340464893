import React, {useContext, useEffect} from "react";
import {AppStateContext, lighterColor} from "..";

export function Contacts() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = lighterColor;
    }, [0]);
    return (
        <div className="column-space-between margin-top">
            <h2 className="margin-top-2 margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Contatti
            </h2>
            <a href="https://www.uqido.com/it/" style={{fontFamily: 'Libre Baskerville, serif'}}><img src="uqido.png" alt={"uqido logo"} className="uqido-logo"/></a>
            
            <h3 className="bold margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}}>Uqido Srl</h3>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>Via Gargagliano 52, int 7<br/>35138 Padova</p>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>T +39 049 8718 624<br/>info@uqido.com</p>
        </div>
    );
};
