import React from "react";

export function Credits() {
    return (
        <div className="column-credits">
            <h2 className="margin-top-2 margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Credits
            </h2>
            <h3 className="bold" style={{fontFamily: 'Libre Baskerville, serif'}}>Invented by</h3>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Donato Simone Frigotto<br/>
                Fausto Panizzolo
            </p>
            <h3 className="bold" style={{fontFamily: 'Libre Baskerville, serif'}}>Scientific Content</h3><p>Donato Simone Frigotto</p>
            <h3 className="bold margin-bottom-4" style={{fontFamily: 'Libre Baskerville, serif'}}>Designed and Realized by</h3>
            <a href="https://www.uqido.com/it/" style={{fontFamily: 'Libre Baskerville, serif'}}><img src="uqido.png" alt={"uqido logo"} className="uqido-logo"/></a>
            <h3 className="bold margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}}>Special Thanks to</h3>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Pier Mattia Avesani<br/>
                Anna Germin<br/>
                Giulia Mastrella<br/>
                Tommaso Rosso<br/>
                Camilla Stevani
            </p>
            <h3 className="bold margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Illustrations by
            </h3>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>
                freepik
            </p>
            <h3 className="bold margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Voice and Translation by
            </h3>
            <p className="credit-item" style={{fontFamily: 'Libre Baskerville, serif'}}>
                Eric Hale<br/>
                Andrea Buttazzi<br/>
                Lara Fernández Alonso<br/>
                Giulia Galvan<br/>
                Giusi Valentini<br/>
                Marianela Manzoni<br/>
                Fernando Manzoni<br/>
                Monica Svensson<br/>
                Jeanette Laewen<br/>
            </p>
            <p className="margin-top-4" style={{fontFamily: 'Libre Baskerville, serif'}}></p>
        </div>
    );
}