import React, {useContext, useEffect} from "react";
import {history, AppStateContext, darkerColor, lighterColor} from "../index";

export function Terms() {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = lighterColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <div className="column">
                <h2 className="margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                    { appState.currentTranslation?.terms_title ?? "" }
                </h2>
                <div className="description" style={{fontFamily: 'Libre Baskerville, serif'}}>
                    { (appState.currentTranslation?.terms_text ?? "").split("\n").map(i => <p style={{fontFamily: 'Libre Baskerville, serif'}} key={i}>{i}</p>) }
                </div>
            </div>
            <button className="input_primary" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/step1")}>
            { appState.currentTranslation?.terms_primary_button ?? "" }</button>
        </div>
    );
}