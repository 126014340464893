import React, {useContext, useEffect} from "react";
import {history, AppStateContext, darkerColor, lighterColor} from "../index";

export function Gdpr () {
    const [appState] = useContext(AppStateContext);
    useEffect(() => {
        document.body.style.backgroundColor = lighterColor;
    }, [0]);
    return (
        <div className="column-space-between">
            <div className="column">
                <h2 className="margin-top-2" style={{fontFamily: 'Libre Baskerville, serif'}}>
                    GDPR Policy
                </h2>
                <div className="description" style={{fontFamily: 'Libre Baskerville, serif'}}>
                <a href="https://www.iubenda.com/privacy-policy/395514" className="iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a><br/>
                
                
                <a href="https://www.iubenda.com/privacy-policy/395514/cookie-policy" className="iubenda-nostyle no-brand iubenda-embed" title="Cookie Policy ">Cookie Policy</a>
                </div>
            </div>
            <button className="input_primary" style={{fontFamily: 'Libre Baskerville, serif'}} onClick={() => history.push("/step1")}>
            { appState.currentTranslation?.terms_primary_button ?? "" }</button>
        </div>
    );
}